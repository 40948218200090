import { Component } from 'react';
import SelectElement from './SelectElement';
import './styles/searchEngine.css';

class SearchEngine extends Component {

  constructor() {
    super();

    this.state = {
      select1: {
        name: 'select1',
        contents: {
          1: 'Un',
          2: 'Deux',
          3: 'Trois'
        },

      }
    }
  }

  render() {
    return (
      <div className="searchEngine">
        <SelectElement name={this.state.select1.name} contents={this.state.select1.contents} />
      </div>
    );
  }


}
export default SearchEngine;
