import { Component } from 'react';
import SelectOption from './SelectOption';
import './styles/selectElement.css';

class SelectElement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: props.name,
            contents: props.contents
        }
    }

    setValues = (values) => {
        this.setState({ values: values });
    }

    render() {
        let options = [];
        let $i = 0;
        for (const content in this.state.contents) {
            $i++;
            options.push(<SelectOption key={$i} value={content} libelle={this.state.contents[content]} selected="false" />);
        }
        return (<select name={this.state.name} className="selectElement">{options}</select>);
    }
}

export default SelectElement;