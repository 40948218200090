import React from 'react';
import ReactDOM from 'react-dom';
import SearchEngine from './SearchEngine';
import './styles/index.css';

ReactDOM.render(
  <React.StrictMode>
    <SearchEngine />
  </React.StrictMode>,
  document.getElementById('root')
);