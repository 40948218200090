import { Component } from 'react';
import './styles/selectOption.css';

class SelectOption extends Component {

    constructor(props) {
        super(props);
        this.state = {
            libelle: props.libelle,
            value: props.value,
            selected: props.selected
        }
    }

    select = () => {
        this.setState({ selected: true });
    }

    render() {
        return (<option value={this.state.value} className="selectOption">{this.state.libelle}</option>);
    }

}

export default SelectOption;